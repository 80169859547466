import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import './styles.css';
import Login from "./components/account/login";
import ClassList from "./components/classes/list/classlist";
import Class from "./components/classes/class/class";
import Registered from "./components/account/registered";
import StudentDetail from "./components/Checkout/StudentDetails/StudentDetail";
import ContactDetails from "./components/Checkout/ContactDetails/ContactDetails";
import Confirmation from "./components/Checkout/Confirmation/Confirmation";
import Payment from "./components/Checkout/Payment/Payment";
import Complete from "./components/Checkout/Payment/Complete";
import AccountManagement from "./components/account/AccountManagement";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/account/registered",
        element: <Registered />,
    },
    {
        path: "/classes/:classid",
        element: <Class />,
    },
    {
        path: "/classes",
        element: <ClassList />,
    },
    {
        path: "/checkout/details",
        element: <StudentDetail />,
    },
    {
        path: "/checkout/contacts",
        element: <ContactDetails />,
    },
    {
        path: "/checkout/confirmation",
        element: <Confirmation />,
    },
    {
        path: "/checkout/payment/:orderid/:paymentgatewayid",
        element: <Payment />,
    },
    {
        path: "/checkout/complete/:orderid/:paymentgatewayid",
        element: <Complete />,
    },
 /*   {
        path: "/accounts",
        element: <AccountManagement />,
    },*/
    {
        path: "*",
        element: <Navigate to={"/"} />,
    }
]);
ReactDOM.createRoot(document.getElementById("root")).render(

    <RouterProvider router={router} />

);

