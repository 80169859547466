import React, { useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Api from "../../Api";
import Required from "../common/Required";

const ForgottenPassword = (props) => {

    const [showSuccess, setShowSuccess] = useState({state:false, emailaddress:''});
    const [showError, setShowError] = useState({ message:'', state:false});

    const [showPinSuccess, setShowPinSuccess] = useState({state:false, emailaddress:''});
    const [showPinError, setShowPinError] = useState({ message:'', state:false});
    const [pinEmailAddress, setPinEmailAddress] = useState('');

    const [formValidation, setFormValidation] = useState({
        isLoading: false,
        showPinValidationError: false,
        showEmailValidationError: false,
        showPasswordValidationError: false,
        showConfirmPasswordValidationError: false,
        showPasswordMatchValidationError: false,
        showApiError: false
    });
    const [showRequestPin, setShowRequestPin] = useState(true);

    const OnRequestPin =(e) => {
        e.preventDefault();
        let form = e.target;
        let formData = new FormData(form);

        const emailaddress = formData.get('tbPinEmailaddress');

        let showEmailValidationError = (emailaddress.length === 0)

        setFormValidation({...formValidation,
            showEmailValidationError: showEmailValidationError
        });

        if (
            !showEmailValidationError
        ) {

            setPinEmailAddress(emailaddress);
            Api.postData(process.env.REACT_APP_API_URL + `frontend/resetpin`, {
                emailAddress: emailaddress
            })
                .then(data => {
                    if (data !== undefined && data.emailSent ) {
                        setShowPinSuccess({state: true, emailaddress: emailaddress});
                        setShowPinError({message: '', state: false});
                        setShowRequestPin(false);
                    } else {
                        let message = 'Failed to send pin.'
                        setShowPinError({message: message, state: true})
                        setShowPinSuccess({state: false, emailaddress: ''});
                    }
                })
                .catch(error => {
                    setShowPinError({message: 'Failed to send pin.', state: true})
                    setShowPinSuccess({state: false, emailaddress: ''});
                });
        }
    }
    const OnResendPin =(e) => {
        e.preventDefault();

        const emailaddress = pinEmailAddress

        let showEmailValidationError = (emailaddress.length === 0)

        setFormValidation({...formValidation,
            showEmailValidationError: showEmailValidationError
        });

        if (
            !showEmailValidationError
        ) {

            setPinEmailAddress(emailaddress);
            Api.postData(process.env.REACT_APP_API_URL + `frontend/resetpin`, {
                emailAddress: emailaddress
            })
                .then(data => {
                    if (data !== undefined && data.emailSent ) {
                        setShowPinSuccess({state: false, emailaddress: ''});
                        setShowError({message: 'Your pin has been re-sent', state: false});
                        setShowRequestPin(false);
                    } else {
                        let message = 'Failed to send pin.'
                        setShowError({message: message, state: true})
                        setShowPinSuccess({state: false, emailaddress: ''});
                    }
                })
                .catch(error => {
                    setShowError({message: 'Failed to send pin.', state: true})
                    setShowPinSuccess({state: false, emailaddress: ''});
                });
        }
    }
    const OnForgottenPassword =(e) => {
        e.preventDefault();
        let form = e.target;
        let formData = new FormData(form);

        const password = formData.get('tbPassword');
        const confirmpassword = formData.get('tbConfirmPassword');
        const emailaddress = formData.get('tbEmailaddress');
        const pin = formData.get('tbPin');

        let showConfirmPasswordValidationError = (confirmpassword.length === 0);
        let showPasswordMatchValidationError = (password !== confirmpassword && !showConfirmPasswordValidationError );
        let showEmailValidationError = (emailaddress.length === 0)
        let showPinValidationError = (pin.length === 0)

        setFormValidation({...formValidation,
            showEmailValidationError: showEmailValidationError,
            showConfirmPasswordValidationError: showConfirmPasswordValidationError,
            showPasswordMatchValidationError: showPasswordMatchValidationError,
            showPinValidationError:showPinValidationError
        });
        if (
            !showEmailValidationError &&
            !showConfirmPasswordValidationError&&
            !showPasswordMatchValidationError &&
            !showPinValidationError
        ) {
            Api.postData(process.env.REACT_APP_API_URL + `frontend/forgottenpassword`, {
                emailAddress: emailaddress,
                password: password,
                pin:pin
            })
                .then(data => {
                    if ((data == undefined || (data.status !== undefined && data.status !== 400) || (data.Status !== undefined && data.Status !== 400))) {
                        setShowSuccess({state: true, emailaddress: emailaddress});
                        setShowError({message: '', state: false})
                    } else {
                        let message = 'Failed to change new password.'

                        if(data.Errors['user:passwordrequirements'] !== undefined && data.Errors['user:passwordrequirements'].length > 0) {
                            message=  data.Errors['user:passwordrequirements'];
                        }
                        else if(data.Errors['user:invalidpin'] !== undefined && data.Errors['user:invalidpin'].length > 0) {
                            message=  data.Errors['user:invalidpin'];
                        }

                        setShowError({message: message, state: true})
                        setShowSuccess({state: false, emailaddress: ''});
                    }
                })
                .catch(error => {
                    setShowError({message: 'Failed to send new password.', state: true})
                    setShowSuccess({state: false, emailaddress: ''});
                });
        }
    }
    const closeDialog = () => {
        setShowSuccess({state:false, emailaddress:''});
        setShowError({ message:'', state:false});
        setShowPinSuccess({state:false, emailaddress:''});
        setShowPinError({ message:'', state:false});
        setPinEmailAddress('');
        setFormValidation({
            isLoading: false,
            showPinValidationError: false,
            showEmailValidationError: false,
            showPasswordValidationError: false,
            showConfirmPasswordValidationError: false,
            showPasswordMatchValidationError: false,
            showApiError: false
        });
        setShowRequestPin(true);
        props.CloseForgottenPasswordDialog();
    }


    return (
        <>
            <div id="closeModal" className="modal-close-btn" onClick={closeDialog}>
                <img src="/assets/images/cross.svg" alt="Close" loading={"lazy"} width={20} height={20}/>
            </div>
            <div className="forgotten-password-component">

            <div className="inner">
                <div className="fp-container">
                    {
                        (showRequestPin) ?
                            (<form className="form" onSubmit={OnRequestPin} >
                                <div className="f-title">Forgotten Password: Request Pin</div>
                                <div className="field-row">
                                    <div className="f-data">
                                        <div className="f-name"><label htmlFor="tbPinEmailaddress">Email Address</label><Required/></div>
                                        <input className="f-input" type="text" name="tbPinEmailaddress" id="tbPinEmailaddress"/>
                                        {(formValidation.showEmailValidationError)? (
                                            <div className="validation-error">Please enter your email address </div>
                                        ) : null }
                                    </div>
                                </div>
                                <div className="action-container">
                                    <button type='submit'  className="class-action btn orange" >Request Pin</button>
                                    {/*<Link onClick={CloseDialog} className="class-action btn orange" >Close</Link>*/}
                                </div>
                                {(showPinError.state) ?
                                    (<span>{showPinError.message}</span>)
                                    :''}
                            </form>)
                        :

                            (<form className="form contact-form" onSubmit={OnForgottenPassword}  autocomplete="off">
                            <div className={"f-title"}>Forgotten Password: Reset</div>

                                {(showSuccess.state) ?
                                    (<><span>Your password has been changed</span>
                                        <div className="action-container">
                                    </div></>)
                                    :(<>
                                        {(showPinSuccess.state) ?
                                            (<div className="field-row">An email has been set
                                                to {showPinSuccess.emailaddress}, this pin is required to change the
                                                password</div>)
                                            :null}
                                        <div className="field-row">
                                            <div className="row-12">
                                                <div className="f-name"><label htmlFor="tbEmailaddress">Email
                                                    Address</label><Required/></div>
                                                    <input className="f-input" type="text" name="tbEmailaddress" id="tbEmailaddress" defaultValue={pinEmailAddress} />
                                                {(formValidation.showEmailValidationError)? (
                                                    <div className="validation-error">Please enter your email address </div>
                                                ) : null }
                                            </div>
                                        </div>
                                        <div className="field-row">
                                            <div className="row-12">
                                                <div className="f-name"><label htmlFor="tbPin">Pin</label><Required/></div>
                                                    <input className="f-input" type="text" name="tbPin" id="tbPin" defaultValue='' autocomplete="one-time-code" />
                                                {(formValidation.showPinValidationError)? (
                                                    <div className="validation-error">Please enter your pin </div>
                                                ) : null }
                                            </div>
                                        </div>
                                        <div className="field-row">
                                            <div className="row-12">
                                                <div className="f-name"><label
                                                    htmlFor="tbPassword">Password</label><Required/></div>
                                                    <input className="f-input" type="password" name="tbPassword" id="tbPassword"  />

                                                {(formValidation.showPasswordValidationError)? (
                                                    <div className="validation-error">Please enter your password</div>
                                                ) : null }
                                            </div>
                                        </div>
                                        <div className="field-row">
                                            <div className="row-12">
                                                <div className="f-name"><label htmlFor="tbConfirmPassword">Confirm
                                                    Password</label><Required/></div>
                                                <input className="f-input" type="password" name="tbConfirmPassword"
                                                       id="tbConfirmPassword"/>

                                                {(formValidation.showConfirmPasswordValidationError) ? (
                                                    <div className="validation-error">Please enter your confirmation
                                                        password </div>
                                                ) : null}

                                                {(formValidation.showPasswordMatchValidationError) ? (
                                                    <div className="validation-error">Your passwords do not match</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="field-row">
                                            <div className="row-12">
                                                <span className="password-info">Your password needs to contain numbers and letters and at least one capital letter and one special character %!@#$%^&*()?/>.;'\|+=</span>
                                            </div>
                                        </div>

                                        <div className="action-container">
                                            <button type='submit' className="class-action btn orange">Change Password
                                            </button>
                                            <button type='button' onClick={OnResendPin}
                                                    className="class-action btn cancel"> Resend Pin
                                            </button>
                                            {/*<Link onClick={CloseDialog} className="class-action btn cancel" >Close</Link>*/}
                                        </div>
                                        {(showError.state) ?
                                            (<span>{showError.message}</span>)
                                            :''}

                                    </>)}


                        </form>)
                    }

                </div>
            </div>
        </div>
        </>
    )
}
export default ForgottenPassword;
