import React, { useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../../Api";
import Page from "../../layouts/page";
import Progress from "../Progress/progress";
import ReactGA from "react-ga4";

const Complete = (props) => {
    ReactGA.initialize('G-YMQ1Q3Q54L');


    let { orderid , paymentgatewayid } = useParams();
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState({
        isLoading: true,
        orderid:'',
        paymentgatewayid:'',
        userid:'',
        orderstatus:''
    });
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true,
        clear: false
    });


    useEffect(() => {
        loadCart();
    }, [])
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);
    useEffect(() => {
        if(cartItems.isloading !== undefined && cartItems.isloading && cartItems.clear) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.clear]);
    useEffect(() => {
        if(!orderData.isLoading) {
            if (orderData !== undefined && (orderData.status === 'payment_intent.succeeded' || orderData.status === 'OK')) {
                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading:true,
                    clear:true,
                    date: cartItems.date
                });

                // Google analytics - track the page view as successful payment
                ReactGA.send({ hitType: "pageview", page: "/checkout/complete", title: "Checkout - Complete Success" });

            } else {
                if (orderData !== undefined && orderData.orderid.length == 0) {
                    // Google analytics - track the page view as failed payment
                    ReactGA.send({ hitType: "pageview", page: "/checkout/complete", title: "Checkout - Complete Fail" });
                } else {
                    setTimeout(completePayment(orderid, paymentgatewayid, sessionStorage.getItem('userid')), 5000);
                }
            }
        }
    }, [orderData])

    useEffect(() => {
        if(cartItems.isloading !== undefined && cartItems.isloading && cartItems.clear) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.clear]);

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if (!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    next: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }
    function loadPage(cart) {
        if(cart == null || cart == undefined || cart.items == null || cart.items.length === 0) {
            navigate('/classes');
        }
        else {
            completePayment(orderid,paymentgatewayid,sessionStorage.getItem('userid'));
        }
    }
    function completePayment(orderid,paymentgatewayid,userid) {
        let data = {
            orderid: orderid,
            paymentgatewayid: paymentgatewayid,
            userid: userid,
            token: sessionStorage.getItem('twtoken') ,
        }
        Api.postAuthorizedData(process.env.REACT_APP_API_URL + `frontend/completepayment`,data,sessionStorage.getItem('twtoken') )
            .then(data => {
                if (data !== undefined && (data.status !== 400 || data.Status !== 400)) {
                    setOrderData({...orderData, isLoading: false, orderid: orderid, paymentgatewayid: paymentgatewayid,userid: userid, status: data.status,email : data.email});

                } else {

                }
            })
            .catch(error => {
                var c ='';
            });
    }

    return (
        <Page pageTitle={"Checkout/Complete"} metaTitle={"Complete | Checkout | Little House of Science"} metaDescription={"Little House of Science checkout"}   cartItems={cartItems.items} enableShowCart={false}>
            <div className="payment-form-component">

                <div className="inner">
                    <div className="payment-container">
                        <Progress step={5} />
                        {(!orderData.isLoading) ? (
                            ((orderData.status === 'payment_intent.succeeded' || orderData.status === 'OK'))  ? <div className={"checkout-message success"}> Your payment has been completed successfully.<br/> An email has been sent to {(orderData.email)} </div>:
                            (orderData.status == "payment_intent.failed")  ? <div className={"checkout-message failed"}> Your payment has failed. </div> :
                            (orderData.status == "payment_intent.cancelled") ? <div className={"checkout-message cancelled"}>Your payment has been cancelled</div> : "<div className={\"checkout-message cancelled\"}>Sorry could not complete the payment</div>"
                        ) :""}
                    </div>
                </div>
            </div>
        </Page>
    )
}
export default Complete;
