import React, { useEffect, useState} from "react";
import Page from "../../layouts/page";
import {useParams} from "react-router-dom";
import Api from "../../../Api";
import helperFunctions from "../../../helperFunctions";
import Loading from "../../common/loading/loading";
import Banner from "./banner";
import ReactGA from "react-ga4";

const Class = (props) => {

    ReactGA.initialize('G-YMQ1Q3Q54L');

    // URL parameter
    let { classid } = useParams();
    const [courseData, setCourseData] = useState({
        isLoading: true,
        course: null,
    });
    const [formData, setFormData] = useState({
        numberOfStudents: 0,
        fee: null,
        totalPrice:0,
        courselessons:[]
    });
    const [showLessons, setShowLessons] = useState(false);
    const [showItemAddedToCart, setShowItemAddedToCart] = useState(false);
    const [showDatesLabel, setshowDatesLabel] = useState('Click to select your class dates');
    const [totalPrice,setTotalPrice] =useState(0);
    const [courseLessons, setCourseLessons] = useState({
        isloading:true,
        lessons:[],
        reloading:false
    });
    const [showEnrolInFullCourse, setShowEnrolInFullCourse] = useState(false);
    const [courseLessonAccordion, setCourseLessonAccordion] = useState({
        isloading:true,
        lessons:[]
    });
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true,
        cartupdated:false,
        date:undefined
    });
    const [loadCourseError, setloadCourseError] = useState(false);

    useEffect(() => {
        loadCart();
    }, []);
    useEffect(() => {
        let numberOfStudents = parseInt(formData.numberOfStudents);

        if(formData.courselessons.length >0) {


            let selectedcount = courseLessons.lessons.filter(x => x.checked).length;
            let label = selectedcount+' lesson';
            if(selectedcount>1) {
                label = label + 's selected';
            }
            else {
                label = label + ' selected';
            }

            setshowDatesLabel(label );



            if(showEnrolInFullCourse) {
                if(numberOfStudents > 0) {
                    setTotalPrice(courseData.course.defaultFee.amount * numberOfStudents);
                }
                else {
                    setTotalPrice(courseData.course.defaultFee.amount);
                }
            }
            else {
                let total = 0;
                if(formData.courselessons.filter(x => x.checked).length > 0) {
                    formData.courselessons.filter(x => x.checked).map(lesson => {
                        var fee = lesson.fees.filter(fee => fee.type.code == 'DEFAULTFEE')[0];
                        total += fee.amount;
                    });

                    if (numberOfStudents > 0) {
                        setTotalPrice(total * numberOfStudents);
                    } else {
                        setTotalPrice(total);
                    }
                }
                else {
                    total = courseData.course.defaultFee.amount;
                }
            }
        }
        else {
            if(courseData.course !== null && courseData.course !== undefined && courseData.course.length > 0) {
                setshowDatesLabel('Click to select your class dates');
                if (!courseData.isLoading) {
                    if (numberOfStudents > 0) {
                        setTotalPrice(courseData.course.defaultFee.amount * numberOfStudents);
                    } else {
                        setTotalPrice(courseData.course.defaultFee.amount);
                    }
                }
            }
        }
    }, [formData]);
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.items]);

    useEffect(() => {
        if(cartItems.cartupdated !== undefined && cartItems.cartupdated) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.cartupdated]);

    useEffect(() => {
        if(courseLessons.reloading) {
            reloadCart(cartItems.items,courseLessons.lessons);
        }
        else {
            let totalPrice = 0
            let numberOfStudents = 0;
            if(cartItems.items !== undefined && cartItems.items.length > 0) {
                cartItems.items.map(item =>{
                    let lesson = courseLessons.lessons.filter(lesson => lesson.courselessonid === item.courselesson.courselessonid)[0];
                    if(lesson !== undefined) {
                        numberOfStudents = item.numberOfStudents;
                    }
                });
            }



            setFormData({
                ...formData,
                courselessons: courseLessons.lessons,
                numberOfStudents: numberOfStudents,
                totalPrice: totalPrice
            });
        }
    }, [courseLessons.reloading]);

    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart === undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if (!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    next: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }
    function loadPage(cart) {
        let userToken = '';
        Api.getData(process.env.REACT_APP_API_URL + `frontend/getcoursebyid/${classid}`, userToken)
            .then(data => {
                if (data !== undefined && (data.Status === undefined || data.Status !== undefined && data.Status !== 400)) {
                    // Store the data from the API. below is an example
                    setCourseData({...courseData,
                        isLoading: false,
                        course: data
                    });

                    // Send page view to Google Analytics
                    let ga_course_name = data.name;
                    if (ga_course_name === null || ga_course_name === undefined) {
                        ga_course_name = "Unknown";
                    }
                    ReactGA.send({ hitType: "pageview", page: "/classes/" + classid, title: "Class - " + ga_course_name });

                    let lessons=[];
                    let accordion =[]
                    data.lessons.map(x => {
                        lessons.push({courselessonid: x.courseLessonId, courseid: data.courseId, name: x.fullName, checked: false, date: x.startDateTime,isenrolmentallowed:x.isEnrolmentAllowed,displaymessage:x.displayMessage,size: x.size,currentsize:x.currentSize,fees: x.fees });
                        accordion.push({courselessonid: x.courseLessonId, name: x.fullName, checked: false, description: x.description, date: x.startDateTime})
                    });

                    setCourseLessons({...courseLessons, isloading:false, lessons: lessons,reloading: true});
                    setCourseLessonAccordion({...courseLessonAccordion, isloading:false, items: accordion});
                    setTotalPrice(data.defaultFee.amount);

                   if(data.type !== undefined && data.type.code==='COURSETYPE:CLASS') {
                       setShowEnrolInFullCourse(true);
                   }
                } else {
                    setloadCourseError(true);
                    setCourseData({...courseData,
                        isLoading: false,
                        course: {}
                    });
                    console.log('failed to load classes. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                setloadCourseError(true);
                setCourseData({...courseData,
                    isLoading: false,
                    course: {}
                });
                console.log('failed to load classes');
                console.log(error);
            });
    }
    function saveCart(course,courseLessonIds, numberOfStudents, totalPrice, enrolInfullCourse) {
        let data = (cartItems.items != undefined) ? cartItems.items.filter(x => x.courseId !== course.courseId) : [];

        {courseLessonIds.map(function(lesson){
            data = [...data.filter(x => x.courseLessonId !== lesson.courselessonid),{course: course,courseLessonId: lesson.courselessonid, price: totalPrice, numberOfStudents: numberOfStudents, courselesson: lesson,enrolInfullCourse:enrolInfullCourse }]
        })}

        if(data.length > 0 ) {
            setCartItems({
                ...cartItems,
                items: data,
                isloading:false,
                clear:false,
                cartupdated: false,
                date: cartItems.date
            });
        }
    }
    function updateCart(id) {
        let item =  cartItems.items.filter(item =>  item.courselesson.courselessonid === id)[0];
        if(item !== undefined && item !== null) {
            let cartitems  =[];
            if(item.enrolInfullCourse) {
                cartitems  =  cartItems.items.filter(x => x.course.courseId !== item.course.courseId);
            }
            else {
                cartitems  =  cartItems.items.filter(x => x.courseLessonId !== item.courseLessonId);
            }

            setCartItems({
                ...cartItems,
                items: cartitems,
                studentdetails: cartItems.studentdetails,
                contactdetails: cartItems.contactdetails,
                isloading:false,
                clear:false,
                cartupdated: true,
                date: cartitems.date
            });

            let newlessons =[]
            courseLessons.lessons.map(lesson => {

                if(item.enrolInfullCourse) {
                  if(lesson.courseid === item.course.courseId) {
                      lesson.checked = false;
                      newlessons.push(lesson);
                  }
                  else {
                      newlessons.push(lesson);
                  }
                }
                else
                {
                    if(lesson.courselessonid === item.courseLessonId) {
                        lesson.checked = false;
                        newlessons.push(lesson);
                    }
                    else {
                        newlessons.push(lesson);
                    }
                }
            });

            reloadCart(cartitems,newlessons);
        }
    }
    function reloadCart(cartitems,newlessons) {

        if(cartitems === undefined || cartitems === null || cartitems.length === 0)
            cartitems =cartItems.items;

        if(newlessons === undefined || newlessons === null || newlessons.length === 0)
            newlessons =courseLessons.lessons;

        let numberOfStudents = parseInt(formData.numberOfStudents);
        cartitems.map(item => {
            numberOfStudents = item.numberOfStudents;
            if(item.enrolInfullCourse) {
                    newlessons.map(lesson => {
                        lesson.checked = true
                    });
                    setShowEnrolInFullCourse(true);
            }
            else
            {
                let lesson = newlessons.filter(x => x.courselessonid === item.courseLessonId)[0];
                if(lesson != undefined) {
                    lesson.checked = true;
                    newlessons = newlessons.filter(x => x.courselessonid !== item.courseLessonId);
                    newlessons.push(lesson);
                }
            }
        });
        setCourseLessons({...courseLessons, lessons: newlessons});
        setFormData({
            ...formData,
            courselessons: newlessons,
            numberOfStudents: numberOfStudents
        });
    }
    function clearCart() {
        setCartItems({
            items:[],
            studentdetails:[],
            contactdetails:[],
            isloading:true,
            clear:false,
            cartupdated:true,
            date: new Date().toString()
        });

        let newlessons =[]
        courseLessons.lessons.map(lesson => {
            lesson.checked = false;
            newlessons.push(lesson);
        });

        reloadCart([],newlessons);
    }


    const openViewDirectionsDialog = () => {
        let dialog = document.getElementById('viewDirectionsModal')
        dialog.showModal();
    }
    const closeViewDirectionsDialog = () => {
        let dialog = document.getElementById('viewDirectionsModal')
        dialog.close();
    }
    const onShowDates = () => {
        setShowLessons(!showLessons);
    }
    const onHideDates = () => {
        setShowLessons(!showLessons);
    }
    const onSelectStudents = (event) => {
        let numberOfStudents = event.target.value;
        let totalPrice = 0

        if(formData.fee != null && formData.fee !== undefined) {
            if (numberOfStudents > 0) {
                totalPrice = (formData.fee.amount * numberOfStudents);
            } else {
                totalPrice = formData.fee.amount;
            }
        }
        setFormData({
            ...formData,
            courselessons: formData.courselessons,
            numberOfStudents: numberOfStudents,
            totalPrice: totalPrice
        });

    }
    const onShowLessonDetails = (lesson) => {
        if(lesson === undefined)
            return;

        let item = courseLessonAccordion.items.filter(x => x.courselessonid == lesson.courselessonid);
        if(item.length === 0)
            return;

        item[0].checked = !item[0].checked;

        setCourseLessonAccordion({...courseLessonAccordion, items: [...courseLessonAccordion.items.filter(x => x.courselessonid !== item[0].courselessonid), item[0]]});
    }
    const onAddLessonEvent = event => {
        let courselessonid = event.currentTarget.attributes['data-item'].nodeValue;
        if (courselessonid.length === 0)
            return;

        onAddLesson(courselessonid);
    }
    const onAddLesson = (courselessonid) => {
        if(courselessonid.length === 0)
            return;

        let newCourseLesson = courseLessons.lessons.filter(x => x.courselessonid == courselessonid);
        if(newCourseLesson.length === 0)
            return;

        let listcourses = formData.courselessons.filter(x => x.courselessonid !== newCourseLesson[0].courselessonid);
        listcourses.push(newCourseLesson[0]);

        // add
        setFormData({...formData,
            courselessons: [...formData.courselessons.filter(x => x.courselessonid !== newCourseLesson[0].courselessonid), newCourseLesson[0]],
            numberOfStudents: parseInt(formData.numberOfStudents)
        });

        newCourseLesson[0].checked = true;
        setCourseLessons({...courseLessons, lessons: [...courseLessons.lessons.filter(x => x.courselessonid !== newCourseLesson[0].courselessonid), newCourseLesson[0]]});
    }
    const onRemoveLessonEvent = event => {
        let courselessonid = event.currentTarget.attributes['data-item'].nodeValue;
        if(courselessonid.length === 0)
            return;

        onRemoveLesson(courselessonid);
    }
    const onRemoveLesson = (courselessonid) => {
        if(courselessonid.length === 0)
            return;

        let newCourseLesson = courseLessons.lessons.filter(x => x.courselessonid == courselessonid);
        if(newCourseLesson.length === 0)
            return;

        let currentcourse = formData.courselessons.filter(x => x.courselessonid !== courselessonid);
        // remove
        setFormData({
            ...formData,
            courselessons: currentcourse,
            numberOfStudents: parseInt(formData.numberOfStudents)
        });

        newCourseLesson[0].checked = false;
        setCourseLessons({...courseLessons, lessons: [...courseLessons.lessons.filter(x => x.courselessonid !== newCourseLesson[0].courselessonid), newCourseLesson[0]]});

    }
    const onAddToCart = (course,courselessons, numberOfStudents) => {

        if(showEnrolInFullCourse && course.lessons.filter(lesson => lesson.isEnrolmentAllowed && (lesson.size > lesson.currentSize)).length == courselessons.length) {
            let firstitem = courselessons.sort((a, b) => a.date > b.date ? 1 : -1)[0];
            let lessons = []
            if(firstitem !== undefined) {
                lessons.push(firstitem);
                saveCart(course, lessons, numberOfStudents, totalPrice, showEnrolInFullCourse);
            }
        }
        else {
            saveCart(course,courselessons.filter(lesson => lesson.checked),numberOfStudents ,totalPrice,showEnrolInFullCourse);
        }

        setShowItemAddedToCart(true);
    }
    const onSelectAll = () => {
        let lessons = [];
        courseData.course.lessons.filter(lesson => lesson.isEnrolmentAllowed && (lesson.size > lesson.currentSize))
            .map(lesson => {
                let newCourseLesson = courseLessons.lessons.filter(x => x.courselessonid == lesson.courseLessonId);
                if(newCourseLesson.length !== 0) {
                    newCourseLesson[0].checked = true;
                    lessons.push(newCourseLesson[0]);
                }
            });

        setFormData({...formData,
            courselessons: lessons,
            numberOfStudents: parseInt(formData.numberOfStudents)
        });

        setCourseLessons({...courseLessons, lessons: lessons});

        setShowEnrolInFullCourse(true);
    }
    const onDeSelectAll = () => {
        setShowEnrolInFullCourse(false);

        let lessons = [];
        courseData.course.lessons
            .map(lesson => {
                let newCourseLesson = courseLessons.lessons.filter(x => x.courselessonid == lesson.courseLessonId);
                if(newCourseLesson.length !== 0) {
                    newCourseLesson[0].checked = false;
                    lessons.push(newCourseLesson[0]);
                }
            });

        setCourseLessons({...courseLessons, lessons: lessons});

        setFormData({...formData,
            courselessons: lessons,
            numberOfStudents: parseInt(formData.numberOfStudents)
        });
    }

    return (
        <Page pageTitle={"Camps & Classes"} metaTitle={(!courseData.isLoading)? courseData.course.name : "Little House of Science"} metaDescription={"Little House of Science provides after school science activities for children of all ages. Click to view our holiday and after school timetable here."} cartItems={cartItems.items} updateCart={updateCart} enableShowCart={true} clearCart={clearCart}>

            {(!courseData.isLoading)? (
                <>
                <div className="class-container">
                    {(!loadCourseError && courseData.course !== null && courseData.course !== undefined) ?
                            <>
                            <div className="class-details">
                                <div className="left">
                                    {(courseData.course.imageName !== undefined && courseData.course.imageName.length > 0) ?
                                    <img src={courseData.course.imageUrl+'/'+courseData.course.imageName + '?size=main&autoratio=true'} />
                                        : <img src={courseData.course.imageUrl+'/default.jpg'} />}

                                    {(courseData.course.info !== undefined && courseData.course.info.length >0) ? <div className="info" dangerouslySetInnerHTML={{__html: courseData.course.info}}>

                                    </div>: null}


                                </div>
                                <div className="right">
                                    <h1>{courseData.course.name}</h1>
                                    <span>{helperFunctions.formatDateForDisplay(
                                        new Date(courseData.course.startDate.substring(0,courseData.course.startDate.indexOf('T'))),{
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        })} to {helperFunctions.formatDateForDisplay(
                                        new Date(courseData.course.endDate.substring(0,courseData.course.endDate.indexOf('T'))),{
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        })}</span>
                                    <div dangerouslySetInnerHTML={{__html: courseData.course.intro}} />
                                    <div className="c-info">
                                        <div className="icon"><img src="/assets/images/icon-clock.svg" alt=""/></div>
                                        <div className="info">{helperFunctions.getTimeForDisplay(
                                            courseData.course.startDate)} - {helperFunctions.getTimeForDisplay(
                                            courseData.course.endDate)}</div>
                                    </div>

                                    {(courseData.course.venue !== undefined && courseData.course.venue.address !== undefined) ?
                                        <div className="venue-container c-info">
                                            <div className="icon"><img src="/assets/images/icon-map-pin.svg" alt=""/></div>
                                            <div className="info">
                                                <span>{courseData.course.venue.name}</span><br/>
                                                <span>
                                                {(courseData.course.venue.address.addressLine1.length > 0) ? courseData.course.venue.address.addressLine1 + ',' : ""}
                                                    {(courseData.course.venue.address.addressLine2.length > 0) ? courseData.course.venue.address.addressLine2 + ',' : ""}
                                                    {(courseData.course.venue.address.addressLine3.length > 0) ? courseData.course.venue.address.addressLine3 : ""}
                                                </span><br/>
                                                <span>
                                                 {(courseData.course.venue.address.suburb.length > 0) ? courseData.course.venue.address.suburb + ',' : ""}
                                                    {(courseData.course.venue.address.city.length > 0) ? courseData.course.venue.address.city : ""}
                                                </span>
                                                <span>{courseData.course.venue.address.postalCode}</span><br/>
                                                {(courseData.course.venue.googleMapsLocation !== undefined && courseData.course.venue.googleMapsLocation.length > 0) ?
                                                    <button onClick={openViewDirectionsDialog}>view directions</button>
                                                    : ""}
                                            </div>
                                        </div>
                                        : ""}

                                    <br/>
                                    {(courseData.course.type !== undefined && courseData.course.type.code === "COURSETYPE:CAMP" && courseData.course.isEnrolmentAllowed) ?
                                        <>
                                                <div className="dates-container">
                                        <label htmlFor="ddlDates">Select Dates</label>

                                            {
                                                (!courseLessons.isloading && courseLessons.lessons !== undefined && courseLessons.lessons.length > 0) ?
                                                <>
                                                    <span className="multi-select-button" onClick={onShowDates}>{showDatesLabel}</span>
                                                    {(showLessons && courseData.course.isEnrolmentAllowed) ?

                                                        <div className="multi-select-menu dropdown" role="menu"  >
                                                            <span onClick={onHideDates}><img
                                                                src="/assets/images/cross.svg" alt="Close"
                                                                loading="lazy" width="20" height="20"/></span>
                                                            <ul className="multi-select-menuitems">
                                                                <li  className="listheader">
                                                                    {(showEnrolInFullCourse) ? (
                                                                            <>
                                                                                <input type="checkbox" onChange={onDeSelectAll} checked/>
                                                                                Deselect entire course <br/>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                <input type="checkbox"  onChange={onSelectAll}/>
                                                                                Select entire course <br/>
                                                                            </>
                                                                        )
                                                                    }
                                                                </li>

                                                                {courseLessons.lessons.sort((a, b) => a.date > b.date ? 1 : -1).map(lesson => (
                                                                    (!lesson.isenrolmentallowed || (lesson.size <= lesson.currentsize)) ?
                                                                        <li key={lesson.courselessonid} className="multi-select-menuitem" role="menuitem">
                                                                            <span className="disabled"><input type="checkbox" disabled/>{helperFunctions.getDateTimeForDisplay(lesson.date)}</span>
                                                                            {(lesson.displaymessage !== undefined &&  lesson.displaymessage.length > 0) ?
                                                                                <><span className="disabledmessage"> <div dangerouslySetInnerHTML={{__html: lesson.displaymessage}} /></span><br/></>
                                                                            :
                                                                                <> <span className="disabledmessage">This class is full</span><br/></>}
                                                                        </li>
                                                                     :
                                                                    <li key={lesson.courselessonid} className="multi-select-menuitem" role="menuitem">
                                                                        {lesson.checked ?
                                                                            <input type="checkbox"
                                                                                   data-item={lesson.courselessonid}
                                                                                   onChange={onRemoveLessonEvent} checked/>
                                                                            :
                                                                            <input type="checkbox"
                                                                                   data-item={lesson.courselessonid}
                                                                                   onChange={onAddLessonEvent} checked={false}/>
                                                                        }
                                                                        {helperFunctions.getDateTimeForDisplay(
                                                                        lesson.date)} <br />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                    :''}
                                                </>
                                             :
                                             <span> No Lessons Found</span>}
                                    </div>
                                                <div className="students-container">
                                                    <label htmlFor="ddlDates">Number of students</label>
                                                    <select id="ddlStudents" onChange={onSelectStudents}>
                                                        <option value="">Select number of students</option>
                                                        {(formData.numberOfStudents !== undefined  && parseInt(formData.numberOfStudents) == 1) ?<option value="1" selected>One</option> : <option value="1" >One</option>}
                                                        {(formData.numberOfStudents !== undefined  && parseInt(formData.numberOfStudents) == 2) ?<option value="2" selected>Two</option> : <option value="2" >Two</option>}
                                                        {(formData.numberOfStudents !== undefined  && parseInt(formData.numberOfStudents)== 3) ?<option value="3" selected>Three</option> : <option value="3" >Three</option>}
                                                        {(formData.numberOfStudents !== undefined  && parseInt(formData.numberOfStudents) == 4) ?<option value="4" selected>Four</option> : <option value="4" >Four</option>}
                                                    </select>
                                                </div>
                                        </>
                                        :''}

                                    {(courseData.course.showPrice) ?
                                    <div className="price-container">
                                        £{totalPrice}
                                    </div>
                                        : null }

                                    {(courseData.course.isEnrolmentAllowed) ?

                                        <div className="action-container">
                                            {(parseInt(formData.numberOfStudents) > 0 && formData.courselessons.length > 0) ? (
                                                    <button  className="class-action btn orange orange" onClick={() => onAddToCart(courseData.course,formData.courselessons,parseInt(formData.numberOfStudents))}>add to cart</button>

                                                ) :
                                                <button  className="class-action btn disabled orange" >add to cart</button>
                                            }

                                            {  (showItemAddedToCart) ? <span className="added-to-cart"> Item added to cart</span> :"" }
                                        </div>
                                        :
                                        <div className="action-container">
                                            {(courseData.course.displayMessage !== undefined && courseData.course.displayMessage.length > 0)?
                                            <span className="price-container">{courseData.course.displayMessage}</span>
                                             :
                                            <span className="price-container">This class is full.</span>}
                                        </div>
                                    }

                                    <br/>
                                    {(courseData.course.description !== undefined && courseData.course.description.length >0) ? <div className="content" dangerouslySetInnerHTML={{__html: courseData.course.description}}></div>: null}

                                    <div className="lessons-container">
                                        <ul>
                                            {(courseLessonAccordion.items.sort((a, b) => a.date > b.date ? 1 : -1).map(lesson => (
                                                <li key={lesson.courseLessonId} className="lesson-name" key={lesson.courseLessonId}>
                                                    <span onClick={() => onShowLessonDetails(lesson)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M21.015 6.95474C20.7138 6.67033 20.2392 6.6839 19.9547 6.98503L12.1818 15.2153C12.0831 15.3197 11.9169 15.3197 11.8182 15.2153L4.04526 6.98503C3.76085 6.6839 3.28617 6.67033 2.98503 6.95474C2.6839 7.23915 2.67033 7.71383 2.95474 8.01497L10.7277 16.2452C11.4183 16.9764 12.5817 16.9764 13.2723 16.2452L21.0453 8.01497C21.3297 7.71383 21.3161 7.23915 21.015 6.95474Z" fill="#0062E1"/>
                                                        </svg>
                                                        <span className="name">{lesson.name}</span>
                                                    </span>
                                                    {(lesson.checked) ?
                                                        <div className="class-description">
                                                            {(lesson.description.length > 0) ?
                                                                <div dangerouslySetInnerHTML={{__html: lesson.description}} />
                                                            :
                                                                <div>No lesson description</div>
                                                            }
                                                        </div>
                                                        : ''}
                                                </li>
                                            )))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </>
                        :

                        <>
                            <div className="class-details">

                                <div className="right">
                                    <h1>Course not found!</h1>
                                    <div>Sorry this course cannot be found.</div>
                                </div>
                            </div>

                        </>

                    }
                </div>
                <dialog id="viewDirectionsModal" className="modal">
                    <button id="closeModal" className="modal-close-btn" onClick={closeViewDirectionsDialog}>Close</button>
                    {( courseData.course.venue !== undefined) ?   <div dangerouslySetInnerHTML={{__html: courseData.course.venue.googleMapsLocation }} /> : ""}

                </dialog>
                </>
            ) : (
                <Loading />
            )}

        </Page>
    )
}
export default Class;
