import React, { useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../../../Api";
import Page from "../../layouts/page";
import Progress from "../Progress/progress";
import Required from "../../common/Required";
import ReactGA from "react-ga4";

const StudentDetail = () => {
    ReactGA.initialize('G-YMQ1Q3Q54L');


    const navigate = useNavigate();
    const [studentData, setStudentData] = useState({
        isLoading: true,
        details: [],

    });
    const [formValidation, setFormValidation] = useState({
        isLoading: false,
        showApiError: false,
        errors:[]
    });
    const [genderData, setGenderData] = useState({
        isLoading: false,
        gender:[]
    });
    const [selectedCoursesData, setSelectedCoursesData] = useState({
        selected : [],
        disabled: []
    });
    const [cartData, setCartData] = useState({
        cart : {},
        isloading: true
    });
    const [showStudentForms, setShowStudentForms] = useState(false);
    const [formData,setFormData] = useState({
        numberOfStudents: 0
    })
    const [cartItems, setCartItems] = useState({
        items:[],
        studentdetails:[],
        contactdetails:[],
        isloading:true,
        studentnext:false,
        truestudentcount: 0,
    });

    // Google analytics - track the page view
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/checkout/details", title: "Checkout - Student Details" });
    }, []);

    useEffect(() => {
        loadCart();
    }, []);
    useEffect(() => {
        if(cartItems.studentnext && cartItems.studentdetails !== undefined && cartItems.studentdetails.length > 0) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
            navigate('/checkout/contacts');
        }
    }, [cartItems.studentdetails]);
    useEffect(() => {
        if(cartItems.isloading !== undefined && !cartItems.isloading) {
            loadPage(cartItems);
        }
    }, [cartItems.isloading]);

    function saveCart(data) {
        if(data !== undefined && data !== null ) {
            setCartItems({
                ...cartItems,
                items: cartItems.items,
                studentdetails: data,
                contactdetails: cartItems.contactdetails,
                studentnext:true,
                date: cartItems.date
            });
        }
    }
    useEffect(() => {
        if(cartItems.date !== undefined) {
            window.localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }, [cartItems.date]);

    function isCartExpired(cart) {
        let now =  new Date();
        if(cart === null || cart == undefined || cart.date === undefined) {
            return true;
        }
        else {

            let cartdate = new Date(cart.date)
            cartdate.setDate(cartdate.getDate() + 1);
            if(cartdate < now) {
                return true;
            }
        }
        return false;
    }

    function loadCart () {
        let cart = JSON.parse(window.localStorage.getItem("cart"));
        if (cart != null) {
            if (!isCartExpired(cart)) {
                setCartItems({
                    ...cartItems,
                    items: cart.items,
                    studentdetails: cart.studentdetails,
                    contactdetails: cart.contactdetails,
                    isloading: false,
                    next: false,
                    date: cart.date
                });
            }
            else {
                let now = new Date().toString();
                cart.items = [];
                cart.studentdetails= [];
                cart.contactdetails= []
                cart.isloading= false;
                cart.clear= false;
                cart.cartupdated= false;
                cart.date= now;


                setCartItems({
                    ...cartItems,
                    items: [],
                    studentdetails: [],
                    contactdetails: [],
                    isloading: false,
                    clear: false,
                    cartupdated: false,
                    date: now
                });
            }
        }

        return cart;
    }
    function loadPage(cart) {
       if(cart == null || cart == undefined || cart.items == null || cart.items.length === 0) {
            navigate('/classes');
        }
        else if(!isLoggedIn()) {
            navigate('/?returnurl=/checkout/details');
        }
       // else if(cart !== null && cart !== undefined && cart.items !== null &&  cart.studentdetails !== undefined && cart.studentdetails !== null && cart.studentdetails.length > 0) {
       //      navigate('/checkout/contacts');
       //  }
        else {
            getGender();
            setCartData({...cartData, cart: cart, isloading:false });
            let courses =[];
            cart.items.map(course => {
                if(courses.filter(item => item.courseLessonId === course.courseLessonId).length == 0 ) {
                    courses.push({
                        numberOfStudents: course.numberOfStudents,
                        courseLessonId: course.courseLessonId,
                        name: (course.enrolInfullCourse) ? course.course.fullName : course.courselesson.name,
                        enrolInFullClass: course.enrolInfullCourse
                    });
                }
            });
            setStudentData({
                ...studentData,
                isLoading: false,
                lessons: cart.items,
                courses: courses
            });

            if(cart.truestudentcount !== undefined && cart.truestudentcount > 0) {
                let selectedItems =[]
                for (let i = 1; i <= cart.truestudentcount; i++) {
                    let existingStudent =  cartItems.studentdetails.filter(x => x.id == i)[0];
                    if(existingStudent !== undefined) {
                        let courselessonid = existingStudent.courselessonid;

                        courses.map(course => {
                                if (courselessonid === course.courseLessonId) {
                                    let data = {
                                        student: i,
                                        courseLessonId: course.courseLessonId,
                                        numberOfStudents: course.numberOfStudents,
                                        checked: true,
                                        enrolInFullClass: course.enrolInFullClass
                                    };

                                   // selectedItems = selectedCoursesData.selected.filter(x => x.student !== i || (x.student === i && x.courseLessonId !== course.courseLessonId));
                                    selectedItems.push(data);
                                }
                            }
                        )
                    }
                }
                setSelectedCoursesData({...selectedCoursesData, selected: selectedItems});
                setShowStudentForms(true);
            }

           setFormData({
               ...formData,
               numberOfStudents: cart.truestudentcount
           });
       }
    }

    function isLoggedIn() {
        let userid = sessionStorage.getItem('userid');
        let token =     sessionStorage.getItem('twtoken');
        if(userid !== undefined && userid !== null && token !== undefined && token !== null) {
            return true;
        }
        return  false;
    }
    function getGender() {
        let userToken = '';
        Api.getData(process.env.REACT_APP_API_URL + `frontend/getgender`, userToken)
            .then(data => {
                if (data !== undefined) {
                    setGenderData({...genderData,
                        isLoading: false,
                        gender: data
                    });
                } else {
                    console.log('failed to load advertising sources. Data is undefined.');
                    console.log(data);
                }
            })
            .catch(error => {
                console.log('failed to load advertising sources');
                console.log(error);
            });
    }
    function setArrayValues(array,key,value) {
        if(array.filter(x => x.key == key).length > 0)
            array.filter(x => x.key == key)[0].value = value
        else
            array.push({key: key,value: value});
    }
    function getArrayValue(array,key) {
        if(array.filter(x => x.key == key).length > 0)
            return array.filter(x => x.key == key)[0].value;
        return  null;
    }
    function onSelectCourses(e,student,courseLessonId,numberOfStudents,enrolInFullClass) {
        let isChecked = false;
        if(e !== undefined && e.currentTarget.checked){
            isChecked = e.currentTarget.checked;
        }
        else if(e === undefined) {
            isChecked = true;
        }
        let data = {
            student: student,
            courseLessonId: courseLessonId,
            numberOfStudents: numberOfStudents,
            checked:isChecked,
            enrolInFullClass: enrolInFullClass
        };

        let selectedItems = selectedCoursesData.selected.filter(x => x.student != student || ( x.student == student && x.courseLessonId != courseLessonId));
         selectedItems.push(data);
        setSelectedCoursesData({...selectedCoursesData, selected: selectedItems});
    }

    const onNext = (e) => {
        e.preventDefault();
        let form = e.target;
        let formdata = new FormData(form);
        let cartdata = [];
        let maxStudents =  parseInt(formData.numberOfStudents);
        let errors = [];

        //set error values to false
        for (let b = 1; b < (maxStudents + 1); b++) {
            setArrayValues(errors, 'showEmergencyContactFirstName' + b+ 'ValidationError', false);
            setArrayValues(errors, 'showEmergencyContactLastName' + b+ 'ValidationError', false);
            setArrayValues(errors, 'showEmergencyContactEmail' + b + 'ValidationError', false);
            setArrayValues(errors, 'showEmergencyContactMobile' + b + 'ValidationError', false);

            setArrayValues(errors, 'showEarlyDropOffFirstName' + b + 'ValidationError', false);
            setArrayValues(errors, 'showEarlyDropOffLastName' + b+ 'ValidationError', false);
            setArrayValues(errors, 'showEarlyDropOffEmailAddress' + b + 'ValidationError', false);
            setArrayValues(errors, 'showEarlyDropOffMobile' + b + 'ValidationError', false);

            setArrayValues(errors, 'showLatePickUpFirstName' + b + 'ValidationError', false);
            setArrayValues(errors, 'showLatePickUpLastName' + b + 'ValidationError', false);
            setArrayValues(errors, 'showLatePickUpEmailAddress' + b + 'ValidationError', false);
            setArrayValues(errors, 'showLatePickUpMobile' + b + 'ValidationError', false);
        }
        for (let b = 1; b < (maxStudents + 1); b++) {
            setArrayValues(errors,'showFirstName' + b + 'ValidationError',false);
            setArrayValues(errors,'showLastName'+ b + 'ValidationError',false);
            setArrayValues(errors,'showDateOfBirth' + b + 'ValidationError',false);
            setArrayValues(errors,'showDateOfBirthToOld' + b + 'ValidationError',false);
            setArrayValues(errors,'showGender'+ b + 'ValidationError',false);
            setArrayValues(errors,'showSchoolName' + b + 'ValidationError',false);
            setArrayValues(errors,'showSpecialNeeds' + b + 'ValidationError',false);
        }
        for (let b = 1; b < (maxStudents+1); b++) {
            let firstname = formdata.get('tbFirstName'+b);
            let lastname = formdata.get('tbLastName' +b);
            let middlename = formdata.get('tbMiddleName'+b);
            let dateofbirth = formdata.get('tbDateOfBirth'+b);
            let gender = formdata.get('ddlGender'+b);
            let schoolname = formdata.get('tbSchoolName'+b);
            let specialneeds = formdata.get('tbSpecialNeeds'+b);

            let currentdate = new Date();

            let age = 0;
            if(dateofbirth.length > 0) {
                let dob = new Date(dateofbirth)
                let difference = currentdate.getTime() - dob.getTime();
                age = Math.round(difference / (1000 * 3600 * 24 * 365));
            }

            let selectedCourses = selectedCoursesData.selected.filter(x => x.student == b);

            setArrayValues(errors,'showFirstName'+b + 'ValidationError',(firstname.length === 0));
            setArrayValues(errors,'showLastName'+ b + 'ValidationError',(lastname.length === 0));
            setArrayValues(errors,'showDateOfBirth' + b + 'ValidationError',(dateofbirth.length === 0));
            setArrayValues(errors,'showDateOfBirthToOld'+ b + 'ValidationError',((dateofbirth.length > 0) && age > 15));
            setArrayValues(errors,'showGender'+ b + 'ValidationError',(gender.length === 0));
            setArrayValues(errors,'showSchoolName'+ b + 'ValidationError',(schoolname.length === 0));
            setArrayValues(errors,'showSpecialNeeds'+ b + 'ValidationError',(specialneeds.length === 0));
            setArrayValues(errors,'showSelectCourses'+ b + 'ValidationError',(selectedCourses.length === 0));
            selectedCourses.map(student =>
                cartdata.push({
                    id: b,
                    courselessonid: student.courseLessonId,
                    enrolInFullCourse: student.enrolInFullClass,
                    firstname: firstname,
                    lastname: lastname,
                    middlename: middlename,
                    dateofbirth: dateofbirth,
                    gender: gender,
                    schoolname: schoolname,
                    specialneeds: specialneeds
                })
            )
        }

        let hasErrors = (errors.filter(x => x.value == true).length > 0);
        if(!hasErrors) {
            saveCart(cartdata);
        }
        else {
            setFormValidation({
                ...formValidation,
                errors: errors,
            });
        }
    }
    const onCancel = (e) => {
        navigate('/classes');
    }
    const getContent = ()  =>{
        let form = [];
        let numberofstudents =  parseInt(formData.numberOfStudents);
        if(numberofstudents > 0) {
            for (let i = 1; i <= numberofstudents; i++) {
                let existingStudent =  cartItems.studentdetails.filter(x => x.id == i)[0];
                let hasExistingStudent =  (existingStudent !== undefined);
                let firstname = (hasExistingStudent) ? existingStudent.firstname : '';
                let middelname = (hasExistingStudent) ? existingStudent.middlename : '';
                let lastname = (hasExistingStudent) ? existingStudent.lastname : '';
                let gender = (hasExistingStudent) ? existingStudent.gender : '';
                let dateofbirth = (hasExistingStudent) ? existingStudent.dateofbirth : '';
                let schoolname = (hasExistingStudent) ? existingStudent.schoolname : '';
                let specialneeds = (hasExistingStudent) ? existingStudent.specialneeds : '';
                let courselessonid = (hasExistingStudent) ? existingStudent.courselessonid :'';

                form.push(
                    <div key={i}>
                        <div className="student-container">
                            <div className="student-count">Student {i}</div>
                            <div className="field-row student-name">
                                <div className="field">
                                    <label htmlFor={'tbFirstName' + i}>First Name</label><Required/>
                                    <input type="text" name={'tbFirstName' + i} id={'tbFirstName' + i} defaultValue={firstname}/>
                                    {(getArrayValue(formValidation.errors, 'showFirstName' + i + 'ValidationError')) ? (
                                        <div className="validation-error">Please enter your first name</div>
                                    ) : null}
                                </div>
                                <div className="field">
                                    <label htmlFor={'tbMiddleName' + i}>Middle Names</label>
                                    <input type="text" name={'tbMiddleName' + i} id={'tbMiddleName' + i} defaultValue={middelname}/>
                                </div>
                                <div className="field">
                                    <label htmlFor={'tbLastName' + i}>Last Name</label><Required/>
                                    <input type="text" name={'tbLastName' + i} id={'tbLastName' + i} defaultValue={lastname}/>
                                    {(getArrayValue(formValidation.errors, 'showLastName' + i + 'ValidationError')) ? (
                                        <div className="validation-error">Please enter your last name</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="field-row student-info">
                                <div className="field">
                                    <label htmlFor={'ddlGender' + i}>Select gender</label><Required/>
                                    <select name={'ddlGender' + i} id={'ddlGender' + i}>
                                        <option value="" key="">select a gender</option>
                                        {(!genderData.isLoading) ? (
                                                (
                                                    genderData.gender.map(source => (
                                                        <option value={source.code} key={source.code} selected={(gender === source.code)}>{source.name}</option>
                                                    ))
                                                ))
                                            : <option key={"loading"}>loading gender types...</option>}
                                    </select>
                                    {
                                        (getArrayValue(formValidation.errors, 'showGender' + i + 'ValidationError')) ? (
                                            <div className="validation-error">Please select your gender</div>
                                        ) : null}
                                </div>
                                <div className="field">
                                    <label htmlFor={'tbDateOfBirth' + i}>Date of birth</label><Required/>
                                    <input type="date" name={'tbDateOfBirth' + i} id={'tbDateOfBirth' + i} defaultValue={dateofbirth}/>
                                    {
                                        (getArrayValue(formValidation.errors, 'showDateOfBirth' + i + 'ValidationError')) ? (
                                            <div className="validation-error">Please enter your date of birth</div>
                                        ) : null}

                                    {
                                        (getArrayValue(formValidation.errors, 'showDateOfBirthToOld' + i + 'ValidationError')) ? (
                                            <div className="validation-error">Little House of Science only accepts
                                                students up to the age of 15 years.</div>
                                        ) : null}
                                </div>
                                <div className="field">
                                    <label htmlFor={'tbSchoolName' + i}>School Names</label><Required/>
                                    <input type="text" name={'tbSchoolName' + i} id={'tbSchoolName' + i} defaultValue={schoolname}/>
                                    {
                                        (getArrayValue(formValidation.errors, 'showSchoolName' + i + 'ValidationError')) ? (
                                            <div className="validation-error">Please enter your school name</div>
                                        ) : null}
                                </div>
                            </div>
                            <div className="field-row student-additional-info">
                                <div className="row-12">
                                    <label htmlFor={'tbSpecialNeeds' + i}>Please list any special needs\allergies</label><Required/>
                                    <input type="text" name={'tbSpecialNeeds' + i} id={'tbSpecialNeeds' + i} defaultValue={specialneeds}/>
                                    {(getArrayValue(formValidation.errors, 'showSpecialNeeds' + i + 'ValidationError')) ? (
                                        <div className="validation-error">Please enter any special needs\allergies</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="field-row assign-classes">
                                <label>Assign student to classes</label><Required/>
                                <div >
                                        {
                                            studentData.courses.map(course => (
                                                <div key={course.courseLessonId} className="field">
                                                     <input type="checkbox"
                                                            checked={((selectedCoursesData.selected.filter(x => x.courseLessonId == course.courseLessonId && x.student === i)[0] !== undefined &&
                                                                       selectedCoursesData.selected.filter(x => x.courseLessonId == course.courseLessonId && x.student === i)[0].checked) ||
                                                                       (selectedCoursesData.selected.filter(x => x.courseLessonId == course.courseLessonId && x.student === i)[0] === undefined && courselessonid === course.courseLessonId))}
                                                            onChange={(e) => onSelectCourses(e, i, course.courseLessonId, course.numberOfStudents, course.enrolInFullClass)} />
                                                        <label>{course.name}</label>
                                                      {
                                                          (course.enrolInFullClass) ? (<span> Full Course</span>) : ''
                                                      }
                                                </div>
                                                )
                                            )
                                        }
                                        {(getArrayValue(formValidation.errors, 'showSelectCourses' + i + 'ValidationError')) ? (
                                            <div className="validation-error">Please select courses for student</div>
                                        ) : null}
                                </div>

                            </div>
                        </div>
                    </div>
                );
            }
        }
        else {
            form.push(
                <div>
                    <div className="student-container">
                        Please select the number of students you want to enrol.
                    </div>
                </div>
            );
        }
        return form;
    }
    const onSelectStudents = (event) => {
        let numberOfStudents = parseInt(event.target.value);

        setFormData({
            ...formData,
            numberOfStudents: numberOfStudents
        });


        setCartItems({
            ...cartItems,
            items: cartItems.items,
            studentdetails: cartItems.studentdetails,
            contactdetails: cartItems.contactdetails,
            isloading: cartItems.isloading,
            studentnext: cartItems.studentnext,
            truestudentcount: numberOfStudents,
            date: cartItems.date
        });

        let selectedItems=[]
        for (let i = 1; i <= numberOfStudents; i++) {
            let existingStudent =  cartItems.studentdetails.filter(x => x.id == i)[0];
            if(existingStudent !== undefined) {
                let courselessonid = existingStudent.courselessonid;

                studentData.courses.map(course => {
                        if (courselessonid === course.courseLessonId) {
                            let data = {
                                student: i,
                                courseLessonId: course.courseLessonId,
                                numberOfStudents: course.numberOfStudents,
                                checked: true,
                                enrolInFullClass: course.enrolInFullClass
                            };

                            //selectedItems = selectedCoursesData.selected.filter(x => x.student != i || (x.student == i && x.courseLessonId != course.courseLessonId));
                            selectedItems.push(data);


                        }
                    }
                )
            }
            }
        setSelectedCoursesData({...selectedCoursesData, selected: selectedItems});

       setShowStudentForms(true);


        if(numberOfStudents > 0) {
            setShowStudentForms(true);
        }
    }

    return (
        <Page pageTitle={"Checkout/Student Details"} metaTitle={"Student details | Checkout | Little House of Science"} metaDescription={"Little House of Science checkout"}  cartItems={cartItems.items} enableShowCart={false}>
            <div className="student-detail-component">

                <div className="inner">
                    <div className="studentdetails-container">
                        {
                            <form className="form" onSubmit={onNext} >

                                    {(!studentData.isLoading) ?(
                                        <>
                                            <Progress step={1}/>
                                            <div className="students-container">
                                                <label htmlFor="ddlDates">Total number of students you want to enrol in all classes</label>
                                                <select id="ddlStudents" onChange={onSelectStudents}>
                                                    <option value="">Select number of students</option>
                                                    <option value="1" selected={((formData.numberOfStudents !== undefined && formData.numberOfStudents == 1) ||
                                                                                 (cartItems.truestudentcount !== undefined && cartItems.truestudentcount == 1)
                                                                                )} >One</option>
                                                    <option value="2"selected={((formData.numberOfStudents !== undefined && formData.numberOfStudents == 2) ||
                                                                                    (cartItems.truestudentcount !== undefined && cartItems.truestudentcount == 2)
                                                                                )} >Two</option>
                                                    <option value="3"selected={((formData.numberOfStudents !== undefined && formData.numberOfStudents ==3) ||
                                                                                    (cartItems.truestudentcount !== undefined && cartItems.truestudentcount == 3)
                                                                                )} >Three</option>
                                                    <option value="4"selected={((formData.numberOfStudents !== undefined && formData.numberOfStudents == 4) ||
                                                                                    (cartItems.truestudentcount !== undefined && cartItems.truestudentcount == 4)
                                                                                )} >Four</option>
                                                </select>
                                            </div>
                                            {(showStudentForms) ? getContent() : ''}
                                        </>

                                    )
                                 : <div>Loading page...</div>}

                                <div className="action-container">
                                    <button className="class-action btn cancel"  onClick={() => onCancel()}>Back</button>
                                    <button className="class-action btn orange" type="submit">Next</button>
                                </div>
                                <div className="field-row actions">
                                    <div className="row-12">
                                        <span className="requiredMessage">Fields marked with a * are required.</span>
                                    </div>
                                </div>
                            </form>
                        }

                    </div>
                </div>
            </div>
        </Page>
    )
}
export default StudentDetail;
